$(document).foundation();

// Scroll
	$(window).scroll(function(){
		var st = $(this).scrollTop();
		if( st >= 200){
			$('#top').addClass('minimized');
      $('.categoryNav').addClass('minimized');
      $('#searchBar').addClass('minimized');
		}else{
			$('#top').removeClass('minimized');
      $('.categoryNav').removeClass('minimized');
      $('#searchBar').removeClass('minimized');
		}
	});

  /* Hide Header on on scroll down */
$(document).ready(function () {
  
  'use strict';
  
   var c, currentScrollTop = 0,
       navbar = $('header');

   $(window).scroll(function () {
      var a = $(window).scrollTop();
      var b = navbar.height();
     
      currentScrollTop = a;
     
      if (c < currentScrollTop && a > b + b) {
        navbar.addClass("scrollUp");
      } else if (c > currentScrollTop && !(a <= b)) {
        navbar.removeClass("scrollUp");
      }
      c = currentScrollTop;
  });
  
});



// Hamburger menu
  $(function(){
  $('#nav-icon3').click(function(){
    $(this).toggleClass('open');
    $('#mainNav').toggleClass('open');
    $('#top').toggleClass('open');
    $('body').toggleClass('fixed');
  });
});

// Meny accordion
$('.menyTree .childs > a.open').click(function(e) {
    e.preventDefault();
  
    var $this = $(this);
  
    if ($this.next().hasClass('show')) {
        $this.next().removeClass('show');
        $this.removeClass('active');
        // $this.next().slideUp(350);
    } else {
        $this.parent().parent().find('li .inner').removeClass('show');
        $this.parent().parent().find('.childs a').removeClass('active');
        //$this.parent().parent().find('li a').removeClass('active');
        // $this.parent().parent().find('li .inner').slideUp(350);
        $this.next().toggleClass('show');
        $this.addClass('active');
        // $this.next().slideToggle(350);
    }
});



// Search
$("#search").click(function(){
  $("div.searchBar").fadeIn(400);
  $('#searchBar input').focus();
  $(this).toggleClass('hide');
  $('.slideText h1').toggleClass('hide');
  $('body').toggleClass('fixed');
});

// Search close
$("#closesearch").click(function(){
  $("div.searchBar").fadeOut(400);
  $("#search").removeClass('hide');
  $(".slideText h1").removeClass('hide');
  $('body').removeClass('fixed');
});

// Search close on esc
$(document).keyup(function(e) {
    if (e.which == 27) {
        $("div.searchBar").fadeOut(400);
        $("#search").removeClass('hide');
        $(".slideText h1").removeClass('hide');
        $('body').removeClass('fixed');
    }
});

// Slideshow
  $(function(){
    $(".slideshow li, .subPageTop, .imgSwap").each(function(){
        var d = $(this), i = d.find('img').first();
        if(i.length) {
          d.css({
            'background-image' : 'url(' + i.attr('src') + ')'
          });
        i.remove();
      }
    });
  });


// SLIDESHOW FUNCTIONS



// Flexslider
  $('.flexslider').flexslider({
    animation: "fade"
  });



// Scroll anchor

$(function() {
  $('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 500);
        return false;
      }
    }
  });
});

// Footer bottom 
function footerAlign() {
  $('footer').css('display', 'block');
  $('footer').css('height', 'auto');
  var footerHeight = $('footer').outerHeight();
  $('body').css('padding-bottom', footerHeight);
  $('footer').css('height', footerHeight);
}

$(document).ready(function(){
  footerAlign();
});

$( window ).resize(function() {
  footerAlign();
});




